<template>
  <div>
    <el-dialog
      :title="etitle"
      :visible.sync="dialogTableVisible"
      width="300px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        label-width="80px"
      >
        <el-form-item label="分类名称" prop="BasicName">
          <el-input
            v-model="formData.BasicName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="日结短信" prop="DayEndSms">
          <el-select v-model="formData.DayEndSms" placeholder="请选择日结短信">
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button type="primary" @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "../../../../../api/service.js";
export default {
  props: {
    etitle: {
      type: String,
      default: "添加",
    },

    dialogTableVisible: {
      type: Boolean,
      default: false,
    },

    callFatherFunction: {
      type: Function,
    },

    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      formRules: {
        BasicName: [
          { required: true, message: "请输入级别名称", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 8 个字符", trigger: "blur" },
        ],

        DayEndSms: [
          { required: true, message: "请选择级别状态", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //
    onCloseDialog(formName) {
      this.callFatherFunction();
      this.$refs[formName].resetFields();
      this.$emit("closeDialog");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let submitData = {
              BasicName: this.formData.BasicName,
              DayEndSms: this.formData.DayEndSms,
            };
            // 如果有 LevelGuid 就是修改
            if (this.formData.BasicGuid) {
              submitData.BasicGuid = this.formData.BasicGuid;
              let { data, errcode, errmsg } = await service.updateItembasic(
                submitData
              );
              if (errcode == 0) {
                this.onCloseDialog("formRules");
                this.$message({
                  message: "修改成功!",
                  type: "success",
                });
              } else {
                this.$message.error(errmsg);
              }
            } else {
              let { data, errcode, errmsg } = await service.addItembasic(
                submitData
              );
              if (errcode == 0) {
                this.onCloseDialog("formRules");
                this.$message({
                  message: "添加成功!",
                  type: "success",
                });
              } else {
                this.$message.error(errmsg);
              }
            }
          } catch (e) {
            console.log(e);
            //TODO handle the exception
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>